import React from "react";
import Layout from "../components/chat-channel/Layout";
import TitleAndMetaTags from "../components/common/TitleAndHeader";
import Container from "../components/chat-channel/Container";

const pagenotfound = require("../assets/images/icons-web/404_img.svg");

export default function PageNotFound() {
  return (
    <>
      <TitleAndMetaTags
        title="Free Conversational AI with Workflow Automation | Workativ Assistant"
        description="Workativ Assistant is the leading provider of free conversational ai and workflow automation in a single platform to automate workplace support. "
        keywords={["IT Helpdesk Chatbot", "HR Chatbot", "Service Desk Chatbot"]}
        ogTitle="Free Conversational AI with Workflow Automation | Workativ Assistant"
        ogDescription="Workativ Assistant is the leading provider of free conversational ai and workflow automation in a single platform to automate workplace support. "
      />
      <Container>
        <Layout
          logoFor="ASSISTANT"
          backgroundColor={"bg_feature _assistant_pricing"}
        >
          <section
            // class="page-not-found-container float-left"
            // style=" width: 100%; padding: 100px 0px;"
          >
            <div class="container">
              <img src={pagenotfound} class="page-not-found-image"></img>
            </div>
          </section>
        </Layout>
      </Container>
    </>
  );
}
